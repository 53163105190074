<template>
  <div>
    <v-alert v-if="!organisation" type="error">No workspace found with that ID</v-alert>
    <div v-else>
      <v-dialog v-model="editModal" width="80%" max-width="850">
        <AdminEditOrganisation :entity="organisation" />
      </v-dialog>

      <TwoColumn>
        <template v-slot:first>
          <v-card-title> Manage Workspace "{{ organisation.organisationName }}" </v-card-title>
        </template>
        <template v-slot:second>
          <v-btn color="primary" @click="clickEditWorkspace">
            <v-icon>{{ icons.mdiPencil }}</v-icon>
            &nbsp; EDIT WORKSPACE
          </v-btn>
        </template>
      </TwoColumn>

      <AdminListUsers :organisation-uuid="organisationUuid" />
    </div>
  </div>
</template>

<script>
import icons from '@/utils/icons'
import TwoColumn from '@/views/utils/TwoColumn.vue'
import AdminListUsers from '@/views/admin/AdminListUsers.vue'
import AdminEditOrganisation from '@/views/admin/AdminEditOrganisation.vue'
import EventBus from '@/classes/EventBus'
import Organisations from '@/classes/Organisations'
import Users from '@/classes/Users'

export default {
  components: {
    TwoColumn,
    AdminListUsers,
    AdminEditOrganisation,
  },
  props: {
    organisationUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons,
      organisation: {
        isLoading: true,
        organisationUuid: this.organisationUuid,
        organisationName: '???',
      },
      editModal: false,
    }
  },
  mounted() {
    Organisations.onLoaded(() => {
      const organisation = Organisations.getById(this.organisationUuid)
      this.organisation = organisation
    })
    EventBus.$on('organisationEdited', () => {
      this.loading = true
      this.editModal = false
      Users.refresh()
    })
  },
  methods: {
    clickEditWorkspace() {
      this.editModal = true
    },
  },
}
</script>
